import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "../aws-exports";
import AWS from 'aws-sdk';
import getCognitoProvider from "./cognite_common";

// Amplify from aws-exports
Amplify.configure(awsconfig);
// Auth from aws-exports
Auth.configure(awsconfig);

function Usermanagement() {
    const [users, setUsers] = useState();
    const [redisp, setRedisp] = useState(false);

    useEffect(() => {
        // ユーザー一覧を取得
        getUserLists()
                .then(result => {
                    setUsers(result)
                }).catch(error => {
                    console.error("ユーザー一覧作成失敗")
                })
    },[redisp])

    function editHandleClick(name){
        history.push({
          pathname: "/regist_user",
          state: {
            text: name
          }
        });
    }
    async function deleteHandleClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const name = e.target.id;
        const confirmed = window.confirm(name + "を削除しますか？");
        let result = false;
        if (confirmed){
            result = await deleteUser(name);
        }
        if (result){
            // リストを再設定
            setRedisp(!redisp);
        }
    }
    async function deleteUser(userName) {
        try {
            const [cognitoServiceProvider, user] = await getCognitoProvider();
            const result = await cognitoServiceProvider.adminDeleteUser({
                UserPoolId: user.pool.userPoolId,
                Username: userName
            }).promise();
            alert(userName + "を削除しました。");
        } catch (error) {
            console.log(error);
            alert(error.message);
            return false;
        }
        return true;
    };

    async function getCognitoUsers() {
        const [cognitoServiceProvider, user] = await getCognitoProvider();
        // ユーザプール内のユーザを取得する (AWS認証情報がないと使えない部分)
        const result = await cognitoServiceProvider.listUsers({ UserPoolId: user.pool.userPoolId }).promise();
        return result.Users;
    }

    const getUserLists = () =>{
        return new Promise((resolve, reject) => {
            try{
                getCognitoUsers().then((cgusers) => {
                    console.log(cgusers);
                    var list = [];
                    for(var i in cgusers){
                        list.push(
                        <tr>
                            <td>
                                <label class="li_left">{cgusers[i].Username}</label>
                            </td>
                            <td>
                                <button class="button" onClick={editHandleClick.bind(this, cgusers[i].Username)}>編集</button>
                            </td>
                            <td>
                                <button class="button" type="submit" id={cgusers[i].Username} value="削除" onClick={deleteHandleClick.bind(this)}>削除</button>
                            </td>
                        </tr>);
                    }
                    return resolve(list);
                });
            }
            catch(e){
                console.log(e);
                return reject('取得失敗');
            }
        })
    }
    const history = useHistory();

    return (
        <div>
            ユーザー管理<br></br>
            <ul>
                <li>
                    <label class="li_left">ユーザー管理</label>
                    <Link　class="button" to={`/regist_user`}>新規登録</Link>
                </li>
                <br></br>
                <table>
                    {/* ここからユーザー一覧のリンクのリストが表示される */}
                    {users}
                    {/* ここまでユーザー一覧のリンクのリストが表示される */}
                </table>
            </ul>
        </div>
    );
}

export default Usermanagement;
