import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/contractorTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Contractor(){
    const [links, setLinks] = useState();
    const [authType, setAuthType] = useState('');
    const [contractorList, setContractorList] = useState([]);
    const location = useLocation();

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (authType == ''){
                    isAdmin();
                }
            }
        });
    },[])
    useEffect(() => {
        if (authType != ''){
            // 契約者一覧のリンクを取得
            getContractorsLinks()
                    .then(result => {
                        setLinks(result)
                        // this.setState({ loading: false });
                    }).catch(error => {
                        console.error("契約者一覧リンク作成失敗:"+error)
                    })
        }
    },[authType])


    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }


    const getContractorsLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    fetch(API_GATEWAY + '/console/v1/contractors',
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("契約企業一覧取得のfetch実行完了")
                        var list = [];
                        for(var i in data.contractors){
                            if (authType != 'admin' && !contractorList.includes(data.contractors[i].contractor_id)){
                                continue;
                            }
                            list.push(
                                <tr>
                                    <td>
                                        <button class="link_style_button" onClick={handleClick.bind(this, data.contractors[i])}>{data.contractors[i].name}</button>
                                    </td>
                                    {/* TODO：編集・削除は３月。削除ボタンの処理未実装 */}
                                    {/* {authType == 'admin' &&
                                    <td>
                                        <button class="button" onClick={editHandleClick.bind(this, data.contractors[i])}>編集</button>
                                    </td>}
                                    {authType == 'admin' && 
                                    <td>
                                        <input class="button" type="submit" value="削除"/>
                                    </td>} */}
                                </tr>
                            );
                        }
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("契約企業一覧取得のfetch失敗："+err)
                        alert("契約企業一覧の取得に失敗しました")
                        return reject('契約企業一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('契約者一覧取得失敗');
                return reject('契約者一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();

    function handleClick(contractorData){
        history.push({
          pathname: "/hotellist",
          state: {
              text: contractorData.name,
              contractorID: contractorData.contractor_id
            }
      });
    }
    function editHandleClick(contractorData){
        history.push({
          pathname: "/edit_contractor",
          state: {
            text: contractorData.name,
            contractorID: contractorData.contractor_id
          }      });
    }
    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        // 先にhotelListをsetして次のstateでauthをsetする
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:contractors'){
                const contractors = attr.Value.split(/\s/);
                setContractorList(contractors);
            }
        });
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority'){
                setAuthType(attr.Value);
            }
        });
    }

    return (
        
        <div>
            <ul>
                <li>
                    <label class="li_left">契約者一覧</label>
                    {/* TODO：新規登録は３月 */}
                    {/* {authType == 'admin' &&
                    <Link　class="button" to={`/regist_contractor`}>新規登録</Link>} */}
                </li>
                <br></br>
                <br></br>
                {/* ここから契約者一覧のリンクのリストが表示される */}
                <table>
                    {links}
                </table>
                {/* ここまで契約者一覧のリンクのリストが表示される */}
            </ul>
        </div>
    );
}
export default Contractor;