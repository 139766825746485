import { createStore, combineReducers  } from "redux";

const countReducer = (
    state = {
      count: 50,
    }
  ) => {
    return state;
  };
  const locksReducer = (
    state = {
        locks:[
            {
                "id" : "1",
                "dayTime" : "2021/11/17 10:11:32",
                "serialNumber" : "1111111",
                "dskCode" : "12345",
                "gateway" : "GWV01-2AB428",
                "floor" : "1F",
                "roomNumber" : "101",
                "battery" : "78%",
                "abnormalityNotification" : "なし",
                "status" : "施錠",
                "RSSI" : "-78"
            },
            {
                "id" : "2",
                "dayTime" : "2021/11/17 10:11:32",
                "serialNumber" : "22222222",
                "dskCode" : "22222",
                "gateway" : "GWV02-2AB428",
                "floor" : "2F",
                "roomNumber" : "201",
                "battery" : "78%",
                "abnormalityNotification" : "なし",
                "status" : "施錠",
                "RSSI" : "-78"
            }
          ]
    }
  ) => {
    return state;
  };


  const rootReducer = combineReducers({
    countReducer,
    locksReducer,
  });

  const store = createStore(rootReducer);

export default store;