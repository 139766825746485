import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/hotellistTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}



function Hotellist() {
    const [links, setLinks] = useState();
    const [authType, setAuthType] = useState('');
    const [hotelList, setHotelList] = useState([]);
    const location = useLocation();
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    
    useEffect(() => {
        isAdmin();
    },[])
    useEffect(() => {
        if (authType != ''){
            // ホテル一覧のリンクを取得
            getHotelLinks()
                    .then(result => {
                        setLinks(result)
                    }).catch(error => {
                        console.error("ホテル一覧リンク作成失敗")
                    })
        }
    },[authType])
    
    const getHotelLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/contractors/"+ location.state.contractorID +"/hotels";

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("ホテル一覧取得のfetch実行完了")
                        var list = [];
                        for(var i in data.hotels){
                            if (authType != 'admin' && !hotelList.includes(data.hotels[i].hotel_id)){
                                continue;
                            }
                            list.push(
                            <tr>
                                {authType == 'admin' &&
                                <td>
                                    <button class="link_style_button li_left" onClick={handleClick.bind(this, data.hotels[i])}>{data.hotels[i].hotel_id}</button>
                                </td>}
                                <td>
                                    <button class="link_style_button li_left" onClick={handleClick.bind(this, data.hotels[i])}>{data.hotels[i].name}</button>
                                </td>
                                {/* TODO：編集・削除は３月。削除ボタンの処理未実装 */}
                                {/* {authType == 'admin' &&
                                <td>
                                    <button class="button" onClick={editHandleClick.bind(this, data.hotels[i])}>編集</button>
                                </td>}
                                {authType == 'admin' &&
                                <td>
                                    <input class="button" type="submit" value="削除"/>
                                </td>} */}
                                
                            </tr>);
                        }
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("ホテル一覧取得のfetch失敗："+err)
                        alert("ホテル一覧の取得に失敗しました")
                        return reject('ホテル一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗："+error)
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    function handleClick(hotelData){
        history.push({
          pathname: "/device_list",
          state: { text: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID}
      });
    }
    function editHandleClick(hotelData){
        history.push({
          pathname: "/edit_hotel",
          state: { text: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID }
      });
    }
    function registHandleClick(){
        history.push({
          pathname: "/regist_hotel",
          state: { contractorID: location.state.contractorID }
      });
    }
    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        // 先にhotelListをsetして次のstateでauthをsetする
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:hotels'){
                const hotels = attr.Value.split(/\s/);
                setHotelList(hotels);
            }
        });
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority'){
                setAuthType(attr.Value);
            }
        });
    }

    return (
        <div>
            <p>{location.state.text}</p><br></br>
            <ul>
                <li>
                    <label class="li_left">ホテル一覧</label>
                    {/* TODO：新規登録は３月 */}
                    {/* {authType == 'admin' &&
                    <button class="button" onClick={registHandleClick.bind(this)}>新規登録</button>} */}
                </li>
                <br></br>
                <br></br>
                <table>
                    <tr>
                        {authType == 'admin' &&
                        <th>
                            <label class="li_left">ID</label>
                        </th>}
                        <th>
                            <label class="li_left">ホテル名</label>
                        </th>
                    </tr>
                {/* ここからホテル一覧のリンクのリストが表示される */}
                {links}
                {/* ここまでホテル一覧のリンクのリストが表示される */}
                </table>
                <li>
                    
                    
                </li>
                <br></br>

            </ul>

        </div>
    );
}

export default Hotellist;
