import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "../aws-exports";
import AWS from 'aws-sdk';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

async function getCognitoProvider() {
    // 現在のユーザープール情報を取得
    const user = await Auth.currentUserPoolUser();
    // ログイン中のユーザのグループに対応したAWS認証情報を取得する
    const credentials = await Auth.currentCredentials();
    // Cognito User Poolの操作クラスにAWS認証情報を渡して初期化する
    const cognitoServiceProvider = new AWS.CognitoIdentityServiceProvider({
        credentials: Auth.essentialCredentials(credentials),
        region: "ap-northeast-1",
    });
    return [cognitoServiceProvider, user];
}

export default getCognitoProvider;
