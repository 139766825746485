import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/test.json';
import { useState } from 'react'

class status extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        alert(this.state.roomNumber);
    }
    lockInfo = null;
    setLockInfo(){
        this.lockInfo = testJson[0];
    }

    handleChange = (event) => {
        let buff = event.target.value;//入力された文字列
        this.setState({roomNumber: buff});
    }




    constructor(props) {
        super(props);
        this.setLockInfo();
        this.state=this.lockInfo;
    }
    

    render() {
        this.setLockInfo();
        return (
            <div>
                利用状況<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">　</label>
                            <label class="li_left">ホテル数</label>
                            <label class="li_left">共用部数</label>
                            <label class="li_left">部屋数</label>
                        </li>
                        <br></br>
                        <li>
                            <label class="li_left"><Link class="li_left" to={`/`}>エスリードホテル</Link></label>
                            <label class="li_left">９棟</label>
                            <label class="li_left">９か所</label>
                            <label class="li_left">432室</label>
                        </li>
                        <li>
                            <label class="li_left"><Link class="li_left" to={`/`}>エスリードホテル</Link></label>
                            <label class="li_left">９棟</label>
                            <label class="li_left">９か所</label>
                            <label class="li_left">432室</label>
                        </li>
                        <li>
                            <label class="li_left"><Link class="li_left" to={`/`}>エスリードホテル</Link></label>
                            <label class="li_left">９棟</label>
                            <label class="li_left">９か所</label>
                            <label class="li_left">432室</label>
                        </li>
                        <li>
                            <label class="li_left"><Link class="li_left" to={`/`}>エスリードホテル</Link></label>
                            <label class="li_left">９棟</label>
                            <label class="li_left">９か所</label>
                            <label class="li_left">432室</label>
                        </li>
                    </ul>
                </form>
            </div>
        );
    }
}
 
export default status; 