import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/device_list_test.json';
import { Storage } from 'aws-amplify';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Device_list() {
    const [keyLinks, setKeyLinks] = useState();
    const [gwLinks, setGwLinks] = useState();
    const [authType, setAuthType] = useState('');
    const [redisp, setRedisp] = useState(false);

    useEffect(() => {
        isAdmin();
    },[])
    useEffect(() => {
        if (authType != ''){
            // 機器一覧のリンクを取得
            getKeyLinks()
                    .then(result => {
                        setKeyLinks(result.keyList)
                        setGwLinks(result.gwList)
                    }).catch(error => {
                        console.error("機器一覧リンク作成失敗")
                    })
        }
    },[authType, redisp])

    const getKeyLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/devices/"+ location.state.hotelID;

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("鍵一覧取得のfetch実行完了")
                        var keyList = [];
                        for(var i in data.locks){
                            keyList.push(
                            <tr>
                                <td>
                                    <label class="li_left"><button class="link_style_button" onClick={lockHandleClick.bind(this, data.locks[i])}>{data.locks[i].serial_number}</button></label>
                                </td>
                                <td>
                                    <label class="li_left">{data.locks[i].floor}</label>
                                </td>
                                <td>
                                    <label class="li_left">{data.locks[i].name}</label>
                                </td>
                                {authType == 'admin' &&
                                <td>
                                    <button class="button" type="submit" id={data.locks[i].lock_id} value="削除" onClick={handleDeleteLockClick.bind(this)}>削除</button>
                                </td>}
                            </tr>
                            );
                        }
                        var gwList = [];
                        for(var i in data.gws){
                            gwList.push(
                            <tr>
                                <td>
                                    <label class="li_left"><button class="link_style_button" onClick={gatewayHandleClick.bind(this, data.gws[i])}>{data.gws[i].serial_number}</button></label>
                                </td>
                                <td>
                                    <label class="li_left">{data.gws[i].floor}</label>
                                </td>
                                <td>
                                    <label class="li_left">-</label>
                                </td>
                                {authType == 'admin' &&
                                <td>
                                    <button class="button" type="submit" id={data.gws[i].gw_id} value="削除" onClick={handleDeleteGwClick.bind(this)}>削除</button>
                                </td>}
                            </tr>
                                );
                                gwList.push(<br></br>);
                        }
                        const list = {"keyList":keyList, "gwList":gwList};
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("鍵一覧取得のfetch失敗："+err)
                        alert("鍵一覧情報の取得に失敗しました")
                        return reject('鍵一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    var hotelName = "";
    const location = useLocation();

    // location.stateが無い時（直接URLを叩いた時）はホームへ遷移
    if(!location.state){
        history.push({
            pathname: "/",
        });
    }
    else{
        hotelName = location.state.text;
    }


    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority'){
                setAuthType(attr.Value);
            }
        });
    }

    async function handleDeleteLockClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const lock_id = e.target.id;
        if (!window.confirm(lock_id + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/devices/locks/"+lock_id;
            console.log("url："+deleteLockUrl)
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    console.log(res.status);
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー："+res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
    async function handleDeleteGwClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const gw_id = e.target.id;
        if (!window.confirm(gw_id + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteGwUrl = API_GATEWAY + "/console/v1/devices/gws/"+gw_id;
            console.log("url："+deleteGwUrl)
            fetch(deleteGwUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    console.log(res.status);
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log(res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }

    const uploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        s3upload(filename, event.target.files[0], event.target.name);
    }

    function s3upload(name, file, type) {
        const filePath = location.state.hotelID + "/" + type + "/" + name.substr(name.lastIndexOf("\\") + 1);
        console.log(filePath);
        Storage.put(filePath, file, {
            level: 'private'
        }).then(result => {
            console.log(filePath + " アップロード完了");
            alert("アップロード完了");
        }).catch(err => {
            console.log(err);
            alert("アップロードに失敗しました");
        });
    }

    function lockHandleClick(locksData){
        history.push({
          pathname: "/setting_doorlock",
          state: { text: locksData.name, lockID: locksData.lock_id }
      });
    }

    function gatewayHandleClick(gatewayData){
        history.push({
          pathname: "/setting_gateway",
          state: {
            text: gatewayData.serial_number,
            gwID: gatewayData.gw_id,
            hotelID: location.state.hotelID
          }
      });
    }

    return (
        <div class="">
            <p>{location.state.text}　機器一覧</p><br></br>
            <ul>
            <label class="li_left">ドアロック</label>
            {/* 登録は現在は非表示 */}
            {/* {authType == 'admin' &&
            <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="ddl" onChange={uploadHandleChange} /></label>}
            &nbsp; */}
            <br></br>
            <br></br>
            <br></br>
            <table class="center">
                <tr>
                    <th>
                        <label class="li_left">Serial Number</label>
                    </th>
                    <th>
                        <label class="li_left">Floor</label>
                    </th>
                    <th>
                        <label class="li_left">roomNumber</label>
                    </th>
                </tr>
                {/* ここから電子錠一覧のリンクのリストが表示される */}
                {keyLinks}
                {/* ここまで電子錠一覧のリンクのリストが表示される */}
            </table>

            <br></br>
            <br></br>
            <label class="li_left">ゲートウェイ</label>
            {/* 登録は現在は非表示 */}
            {/* {authType == 'admin' &&
            <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="gw" onChange={uploadHandleChange} /></label>} */}
            &nbsp;
            <br></br>
            <br></br>
            <br></br>
            <table class="center">
                <tr>
                    <th>
                        <label class="li_left">Serial Number</label>
                    </th>
                    <th>
                        <label class="li_left">Floor</label>
                    </th>
                    <th>
                        <label class="li_left">roomNumber</label>
                    </th>
                </tr>
                {/* ここからゲートウェイ一覧のリンクのリストが表示される */}
                {gwLinks}
                {/* ここまでゲートウェイ一覧のリンクのリストが表示される */}
            </table>
            {/* TODO：共用部コメントアウト */}
            {/* <br></br>
            <br></br> */}
                {/* <ul>
                    <li>
                        <label class="li_left">共用部</label>
                        {authType == 'admin' &&
                        <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="common" /></label>}
                        &nbsp;
                    </li>
                    <br></br>
                    <li>
                        <label class="li_left">Topic</label>
                    </li>
                    <br></br>
                    <li>
                        <label class="li_left"><Link class="li_left" to={`/`}>/lead001/</Link></label>
                    </li>
                </ul> */}
            </ul>
        </div>
    );
}

export default Device_list;
