import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Setting_doorlock from './templates/setting_doorlock';
import Setting_gateway from './templates/setting_gateway';
import Hotellist from './templates/hotellist';
import Edit_hotel from './templates/edit_hotel';
import Regist_hotel from './templates/regist_hotel';
import Device_list from './templates/device_list';
import Contractor from './templates/contractor';
import Status from './templates/status';
import Doorlockregistration from './templates/doorlockregistration';
import Regist_user from './templates/regist_user';
import Regist_contractor from './templates/regist_contractor';
import Usermanagement from './templates/usermanagement';
import Edit_contractor from './templates/edit_contractor';
import './App.css';
import { Link } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut  } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Auth, I18n } from 'aws-amplify';
import store from "./store/index";
import { connect, useSelector  } from "react-redux";
import { useEffect, useState } from 'react';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";

// 以下のような形で日本語と英語を紐づけた辞書を作成する
const dict = {
  ja: {
    "Forgot your password?": " ",
    "Reset password": " "
  },
};

// 作成した辞書を渡して反映させる
I18n.putVocabularies(dict);
I18n.setLanguage("ja");

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App({count}){
    const [admFlag, setAdmFlag] = useState(false);
    const [userName, setUserName] = useState();
    const locks = useSelector((state) => state.locksReducer.locks);

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (!userName){
                    isAdmin();
                }
            }
        });
    },[])
    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        if(user){
          setUserName(user.username)
          console.log("ユーザー："+userName)
        }
        console.log("attrs"+attrs)
        let adm = false;
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority' && attr.Value == 'admin'){
                adm = true;
            }
        });
        setAdmFlag(adm);
    }

    return(
      <AmplifyAuthenticator>
        <AmplifySignIn
        slot="sign-in"
        headerText="LeaLockサービス"
        submitButtonText="ログイン"
        hideSignUp={true}
        formFields={[
          {
            type: "username",
            label: "",
            placeholder: "ID",
            required: true,
          },
          {
            type: "password",
            label: "",
            placeholder: "Password",
            required: true,
          },
        ]}
      />
        <BrowserRouter>
          <div class="overall">
            <header class="header" >
              <br></br>
              　<Link class="header_link" to={`/contractor`}>契約者一覧</Link>
              {/* {admFlag &&
              <Link class="header_link" to={`/status`}>　利用状況</Link>} */}
              {admFlag &&
              <Link class="header_link" to={`/usermanagement`}>　ユーザー管理</Link>}
              {/* {admFlag &&
              <Link class="header_link" to={`/doorlockregistration`}>　ドアロック登録</Link>} */}
              {/* 　<Link class="header_link" to={`/error_status`}>エラー状況</Link> */}
              <Link class="button logout" onClick={() => Auth.signOut()}>ログアウト</Link>

              {/* <p>値取得テスト :{store.getState().test_text}</p>
              <p>Count: {count}</p>
              <ul>
                {locks.map((lock) => (
                <li key={lock.id}>{lock.serialNumber}</li>
                ))}
              </ul> */}

            </header>
            <Switch>
              <Route exact path="/regist_contractor" component={Regist_contractor} />
              <Route exact path="/edit_contractor" component={Edit_contractor} />
              <Route exact path="/setting_doorlock" component={Setting_doorlock} />
              <Route exact path="/setting_gateway" component={Setting_gateway} />
              <Route exact path="/hotellist" component={Hotellist} />
              <Route exact path="/edit_hotel" component={Edit_hotel} />
              <Route exact path="/regist_hotel" component={Regist_hotel} />
              <Route exact path="/device_list" component={Device_list} />
              <Route exact path="/contractor" component={Contractor} />
              <Route exact path="/status" component={Status} />
              <Route exact path="/doorlockregistration" component={Doorlockregistration} />
              <Route exact path="/regist_user" component={Regist_user} />
              <Route exact path="/usermanagement" component={Usermanagement} />
              <Route exact path="/"  render={() => <Redirect to="/contractor" />} />
            </Switch>
          </div>
        </BrowserRouter>
      </AmplifyAuthenticator>
    );
}
const mapStateToProps = (state) => {
  return { count: state.count };
};
export default connect(mapStateToProps)(App);