import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/test.json';
import { useState } from 'react'

function Doorlockregistration() {
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    var lockInfo = null;
    function setLockInfo(){
        lockInfo = testJson[0];
    }

    var handleChange = (event) => {
        let buff = event.target.value;//入力された文字列
        // setState({roomNumber: buff});
    }

    function constructor(props) {
        // super(props);
        setLockInfo();
        // state=lockInfo;
    }
    

        setLockInfo();
        return (
            <div>
                ドアロック登録<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ドアロック登録</label>
                            <button class="button" type="submit" value="登録">登録</button>
                        </li>
                        <li>
                            <label class="li_left">Serial Number</label>
                            <label class="li_left">DSKコード</label>
                        </li>
                        <br></br>
                        <br></br>
                        <li>
                            <Link to={`/`}>ユーザー１</Link>
                        </li>
                        <br></br>
                        <li>
                            <Link to={`/`}>ユーザー２</Link>
                        </li>
                    </ul>
                </form>


            </div>
        );
}
 
export default Doorlockregistration; 